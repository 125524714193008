import BaseTheme from "./components/basetheme";
import Nav from "./components/c-Nav";
import QuickLinks from "./components/c-QuickLinks";
import Map from "./components/c-Map";
import Modal from "./components/c-Modal";
import Filter from "./components/c-Filter";
import Image from "./components/c-Image";
import Slider from "./components/c-Slider";
import Header from "./components/c-Header";
import Accordion from "./components/c-Accordion";
import Inview from "./components/c-Inview";

const components = {
  BaseTheme,
  Header,
  QuickLinks,
  Map,
  Modal,
  Filter,
  Image,
  Nav,
  Slider,
  Inview,
  Accordion,
};

export default components;
