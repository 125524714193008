import Swup from "swup";
import SwupJsPlugin from "@swup/js-plugin";
import SwupBodyClassPlugin from "@swup/body-class-plugin";

import Lenis from "@studio-freight/lenis";

import loadComponents from "gia/loadComponents";
import components from "./components";

// enable components
loadComponents(components);

// enable swup
const swup = new Swup({
  //	containers: ['#main', '#header'],
  containers: ["#main", "#header"],
  cache: false,
  linkSelector:
    'a[href^="' +
    window.location.origin +
    '"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="./"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
  plugins: [
    new SwupBodyClassPlugin(),
    new SwupJsPlugin({
      animations: [
        {
          from: "(.*)",
          to: "(.*)",
          in: (next) => {
            window.scrollTo(0, 0);

            setTimeout(next, 1000);
          },
          out: (next) => {
            setTimeout(next, 1000);
          },
        },
      ],
    }),
  ],
});

const lenis = new Lenis({
  duration: 1.3,
  easing: (t) => 1 - Math.cos((1 * Math.PI) / 2), // https://www.desmos.com/calculator/brs54l4xou
  direction: "vertical", // vertical, horizontal
  gestureDirection: "vertical", // vertical, horizontal, both
  smooth: true,
  mouseMultiplier: 1,
  smoothTouch: false,
  touchMultiplier: 1,
  infinite: false,
});

//get scroll value
lenis.on("scroll", ({ scroll, limit, velocity, direction, progress }) => {});

window.lenis = lenis;

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();
    lenis.scrollTo(this.getAttribute("href"));
  });
});

// Add class on first load to do some nice things

setTimeout(() => {
  document.documentElement.classList.add("is-ready");
}, 1000);

// Add some clases if we want to control some classes on swup load
swup.hooks.on("content:replace", () => {
  loadComponents(components);
  document.documentElement.classList.add("is-replaced");
});

swup.hooks.on("visit:start", (visit) => {
  //   document.documentElement.classList.remove("is-replaced");
});
swup.hooks.on("visit:end", () => {
  document.documentElement.classList.add("is-replaced");
});
