import disableScroll from 'disable-scroll';

import Component from "gia/Component";

export default class Nav extends Component {
  constructor(element) {
    super(element);
  }

  mount() {

    $.fn.toggleData = function (dname) {
      var d = `data-${dname}`;
      return this.is(`[${d}]`) ? this.removeAttr(d) : this.attr(d, '');
    };

    var scrolling = true;

    var wrap = $(".c-sub-menu");
    var context = $(".c-nav-image");

    $(".menu-item-has-children").each(function () {
      $(this).find(context).appendTo($(this).find(wrap));
    });

    $(".c-nav li:not(.menu-item-has-children) a, .c-sub-menu li a").click(function (e) {
      $(".c-sub-menu").delay(800).removeClass("active");
      $(".l-header").removeClass("l-header__white");

      var windowWidth = $(window).width();
      if (windowWidth <= 1360) {
        $(".c-nav").fadeOut('slow').removeClass("active");

      }
    });
    $(".c-nav .menu-item-has-children").click(function (e) {
      e.preventDefault();

      if ($(this).parent().siblings(".c-nav").children().hasClass("active")) {
        $(this)
          .parent()
          .siblings(".c-nav")
          .children(".menu-item-has-children")
          .removeClass("active")
          .children()
          .removeClass("active");
      }
      $(".l-header").removeClass("l-header__white");

      if ($(this).hasClass("active")) {
        $(this)
          .removeClass("active")
          .children(".c-sub-menu")
          .removeClass("active");
      } else {
        $(".l-header").addClass("l-header__white");

        $(this).addClass("active").children(".c-sub-menu").addClass("active");
      }

      var submenu = $(this).children("a").text().trim();

      $(".c-sub-menu.active").find("#c-sub-menu--title").text(submenu);

      console.log(submenu);
    });

    var el = $("#navToggle");

    $("#navToggle").click(function (e) {
      e.preventDefault();
      $(".l-header").toggleClass("l-header__white");
      $(this).toggleClass("active");
      // $("body").toggleClass("noscroll");
      $(".l-header--nav").toggleClass("active");
      $(".l-header--nav").toggleData("data-lenis-prevent");
      $(".c-nav").toggleClass("active");
      //  el.text(el.text() == "Menu" ? "Close" : "Menu");
      return false;
    });

    $(window).resize(function () {
      var windowWidth = $(window).width();
      if (windowWidth <= 1360) {
        $(".l-header").removeClass("l-header__white");

        $(".c-nav").removeClass("active");
        //$("body").removeClass("noscroll");
        $(".l-header--nav").removeClass("active");
        $(".l-header--nav").removeAttr("data-lenis-prevent");

        $("#navToggle").text("Menu");
      }
    });

    $(".c-nav .menu-item-has-children").click(function (e) {
      // Get the height + padding + border of `#masthead`
      var mastHeight = $("#header").outerHeight();

      // Add the height to `.site-content`
      $(".c-sub-menu").css("top", mastHeight);
    });


    $(window).on("resize scroll", function () {
      var mastHeight = $("#header").outerHeight();
      $(".c-sub-menu").css("top", mastHeight);
    });




  }

}
